import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const Seo = ({
    title, description, titleValues, descriptionValues,
}) => {
    const { formatMessage } = useIntl();
    const formattedTitle = typeof title === 'string' ? `${title} | Cheap-Social.com` : formatMessage({ ...title }, titleValues);
    const formattedDescription = typeof description === 'string' ? description : formatMessage({ ...description }, descriptionValues);

    const generateAlternateLinks = () => {
        const alternateLinks = [{
            rel: 'alternate',
            hrefLang: 'en',
            href: `${typeof window !== typeof undefined ? window?.location : null}`,
        }];

        return [...alternateLinks];
    };

    return (
        <Helmet
            title={formattedTitle}
            htmlAttributes={{ lang: 'en' }}
            meta={[
                {
                    name: 'description',
                    content: formattedDescription,
                },
                {
                    name: 'keywords',
                    content: 'Facebook Likes, Facebook Followers, Facebook Post Likes, Facebook Post Share, YouTube View, YouTube Subscribers, Twitter Followers, Twitter Tweets, Twitter reTweets, Twitter Favorites, Instagram Followers, Instagram Photo Likes',
                },
                {
                    charSet: 'utf-8',
                },
                {
                    httpEquiv: 'X-UA-Compatible',
                    content: 'IE=edge',
                },
                {
                    name: 'viewport',
                    content: 'width=device-width, initial-scale=1',
                },
                {
                    name: 'author',
                    content: 'Cheap Social',
                },
                {
                    name: 'copyright',
                    content: 'cheap-social.com',
                },
                {
                    property: 'og:title',
                    content: formattedTitle,
                },
                {
                    name: 'twitter:title',
                    content: formattedTitle,
                },
                {
                    property: 'og:description',
                    content: formattedDescription,
                },
                {
                    name: 'twitter:description',
                    content: formattedDescription,
                },
            ]}
            link={[...generateAlternateLinks()]}
        />
    );
};

export default Seo;
